<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Jaime Javier Coronado Guerrero                                   ###### -->
<!-- ###### @date: septiembre 2022                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->

<template>
  <div>
    <form style="margin-bottom: 0;
                               margin-top: 2px;
                               padding: 0;">
      <v-container style="padding-top: 8px; padding-bottom: 6px; padding-left:8px; display: flex;">

        <v-text-field style="width:20%;" class="me-2 ms-1" v-model="formulario.codigoPedido" label="Código" outlined dense
          hide-details>
        </v-text-field>

        <v-select style="width:20%;" class="me-2" label="Tipo transacción" :items="optionsPedido" outlined dense
          hide-details v-model="formulario.tipoPedido"></v-select>

        <v-select style="width:20%;" class="me-2" v-model="formulario.estado" label="Estado actual" outlined
          :items="optionsEstado" dense hide-details>
        </v-select>

        <v-text-field style="width:20%;" class="me-2" v-model="formulario.numeroGuia" outlined dense label="N° guía"
          hide-details>
        </v-text-field>

        <v-text-field style="width:20%;" class="me-1" v-model="formulario.nitTransportadora" label="NIT transportadora"
          outlined dense hide-details>
        </v-text-field>

      </v-container>
    </form>
    <v-data-table hide-default-footer :headers="headers" :items="informacionEnvio" :page.sync="formulario.page"
      :items-per-page="formulario.itemPorPage" class="elevation-1 tablaPrincipal" height="58vh" loading-text="Cargando..."
      :loading="animacionTabla" fixed-header style="margin: 8px 9px 15px">
      <template v-slot:[`item.soporte`]="{ item }">
        <v-progress-circular v-if="idFilaSeleccionada == item.id" :size="25" color="primary"
          indeterminate></v-progress-circular>
        <v-btn v-if="idFilaSeleccionada != item.id" :disabled="desactivarBoton(item) ||
          deshabilitarDescarga
          " @click="descargaDocumento(item)" icon>
          <v-icon color="blue"> file_download </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.detalles`]="{ item }">
        <v-btn @click="verTablaDetalles(item)" icon>
          <v-icon color="blue"> note_add </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <section style="width: 95%;" class="d-flex justify-end align-center">

      <span class="me-2">ítems por página:</span>

      <v-select style=" max-width: 3.5rem;" class="mr-5 pt-4" small v-model="formulario.itemPorPage" :items="items"
        item-text="number" item-value="numberValue"></v-select>

      <v-pagination small v-model="formulario.page" :length="formulario.totalPage" :total-visible="7"></v-pagination>

    </section>
    <template>
      <div class="divDialog">
        <v-dialog scrollable persistent max-width="70%" v-model="dialog">
          <div>
            <v-card class="cardDetalle" style="border-radius: 0; display: block;">
              <v-card-title style="background-color: #0D47A1; color: white">
                <h4>
                  Detalle de la transacción:
                  <b style="color: white">{{ informacionEncabezado.id }} </b>- Tipo de transacción:
                  <b style="color: white">
                    {{ informacionEncabezado.tipoTransaccion }}
                  </b>
                </h4>
              </v-card-title>
              <v-container style="padding: 1px" id="divDetalle">
                <v-row class="filaDetalle" style="padding: 1%; width: 100%">
                  <v-col>
                    <v-text-field v-model="informacionEncabezado.tipoDocumento" label="Tipo documento" dense filled
                      disabled hide-details>
                    </v-text-field>
                  </v-col>

                  <v-col>
                    <v-text-field v-model="informacionEncabezado.numeroDocumento
                      " filled dense label="Número identificación" disabled hide-details>
                    </v-text-field>
                  </v-col>

                  <v-col>
                    <v-text-field v-model="informacionEncabezado.direccion" label="Dirección" filled dense disabled
                      hide-details>
                    </v-text-field>
                  </v-col>

                  <v-col>
                    <v-text-field label="Número contacto" filled dense disabled hide-details
                      v-model="informacionEncabezado.numeroCelular"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <v-container>
                <v-data-table :footer-props="itemsPerPageOptions" class="tablaDetalle" :headers="headersTablaDetalle"
                  :items="informacionTablaDetalle" fixed-header>
                </v-data-table>
              </v-container>
            </v-card>
            <v-card elevation="0" style=" display: flex; justify-content: end; border-radius: 0;">
              <v-btn text @click="dialog = false" style="margin: 15px; margin-top: 0;" color="error">Cerrar</v-btn>
            </v-card>
          </div>
        </v-dialog>
      </div>
    </template>
  </div>
</template>
 
 
<script>

const debounce = function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

import { mapMutations, mapState } from "vuex";
import { Role } from "@/router/role.js";
import moment from "moment";

export default {
  data() {
    return {
      tab: null,
      userRoles: {},
      roles: Role,
      formulario: {
        nitTransportadora: "",
        estado: "",
        numeroGuia: "",
        tipoPedido: "",
        codigoPedido: "",
        page: 1,
        totalPage: 1,
        itemPorPage: 10,
      },
      nombreArchivo: "",
      pagesCount: 0,
      pageBack: 0,
      pageFront: 1,
      nitTransportadora: "",
      numeroGuia: "",
      codigoPedido: "",
      informacionEnvio: [],
      dialog: false,
      base64: "",
      animacionTabla: false,
      informacionTablaDetalle: [],
      deshabilitarDescarga: false,
      idFilaSeleccionada: null,
      fCreacion: "",
      fModificacion: "",
      fCreadoEn: "",
      itemsPerPageOptions: { 'items-per-page-text': 'Ítems por página' },
      informacionEncabezado: {
        id: 0,
        tipoTransaccion: "",
        digitoVerificacion: 0,
        tipoDocumento: "",
        numeroDocumento: "",
        direccion: "",
        email: "",
        numeroCelular: 0,
      },
      optionsPedido: [
        { text: " Todos", value: "T" },
        { text: " Cliente", value: "C" },
        { text: " Domicilio", value: "D" },
        { text: " Traslado", value: "I" },


      ],
      optionsEstado: [
        { text: " Todos", value: "-1" },
        { text: " Asignado", value: "0" },
        { text: " Recibido", value: "1" },
        { text: " Despachado", value: "2" },
        { text: " Transito", value: "3" },
        { text: " Novedad", value: "4" },
        { text: " Devolución", value: "5" },
        { text: " No entregado", value: "6" },
        { text: " Entregado", value: "7" },
        { text: " Soporte", value: "8" },
      ],
      headers: [
        { text: "Código", align: "center", value: "id", width: "8%", sortable: false, },
        { text: "Tipo transacción", value: "tipoTransaccion", width: "12%", sortable: false, },
        { text: "Destinatario", value: "nombre", width: "20%", sortable: false, },
        { text: "Destino", value: "ciudad", width: "16%", sortable: false, },
        { text: "Estado actual", value: "estadoCohan", width: "12%", sortable: false, },
        { text: "N° guía", value: "numeroGuia", width: "10%", sortable: false, },

        {
          text: "NIT transportadora",
          value: "nitTransportadora",
          width: "12%",
          align: "center",
          sortable: false,
        },
        {
          text: "Soporte guía",
          value: "soporte",
          sortable: false,
          align: "center",
          width: "5%",
        },
        {
          text: "Detalles",
          value: "detalles",
          sortable: false,
          align: "center",
          width: "5%",
        },
      ],
      headersTablaDetalle: [
        {
          text: "Fecha modificación",
          value: "createAt",
          width: "45vh",
          sortable: false,
        },
        { text: "Estado", value: "estadoCohan", width: "45vh", sortable: false, },
        { text: "Novedad", value: "novedad", width: "46vh", sortable: false, },
      ],
      items: [
        { number: "5", numberValue: 5 },
        { number: "10", numberValue: 10 },
        { number: "25", numberValue: 25 },
        { number: "50", numberValue: 50 },
        { number: "100", numberValue: 100 },
      ],
    };
  },
  created() {
    this.userRoles = this.auth.roles;
  },

  methods: {
    ...mapMutations([
      "updateAuth",
      "hideNotify",
      "showSuccess",
      "showBusy",
      "hideBusy",
    ]),
    getAllInfoTracking(resetear = false) {
      this.informacionEnvio = [];
      this.animacionTabla = true;
      if (resetear) this.formulario.page = 1;
      this.$http
        .get("/msa-logistics/tracking", {
          params: {
            page: this.formulario.page - 1,
            size: this.formulario.itemPorPage,
            codigoTransaccion: this.formulario.codigoPedido,
            tipoTransaccion: this.formulario.tipoPedido,
            numeroGuia: this.formulario.numeroGuia,
            estado: this.formulario.estado,
            nitTransportadora: this.formulario.nitTransportadora,
          },
        })
        .then((result) => {
          this.animacionTabla = false;
          this.informacionEnvio = result.data.data;
          console.log(result.data);
          this.formulario.totalPage = result.data.numeroPaginas;
        })
        .catch((error) => {
          console.log(error.toJSON());
          this.animacionTabla = false;
        });
    },
    desactivarBoton(item) {
      if (item.estadoCohan == "Soporte" || (item.tipoTransaccion == 'Domicilio' && item.nombreArchivo != null)) {
        return false;
      } else {
        return true;
      }
    },
    descargaDocumento(item) {
      if (item.estadoCohan == "Soporte" || (item.tipoTransaccion == 'Domicilio' && item.nombreArchivo != null)) {
        this.deshabilitarDescarga = true;
        this.idFilaSeleccionada = item.id;
        this.$http
          .post("/msa-logistics/tracking/download", { nombreArchivo: item.nombreArchivo, tipoUrl: item.tipoUrl })
          .then((result) => {
            this.idFilaSeleccionada = null;
            this.deshabilitarDescarga = false;
            this.base64 = result.data;
            const tempLink = document.createElement("a");
            tempLink.href = `data:application/pdf;base64,${this.base64}`;
            tempLink.setAttribute("download", item.nombreArchivo);
            tempLink.click();
          })
          .catch((error) => {
            this.idFilaSeleccionada = null;
            this.deshabilitarDescarga = false;
            console.log(error);
          });
      } else {
        return null;
      }
    },
    verTablaDetalles(item) {
      moment.updateLocale("fr", { invalidDate: "Fecha no disponible" });
      this.$http
        .get("/msa-logistics/tracking/detalle", {
          params: {
            id: item.id,
          },
        })
        .then((result) => {
          this.informacionTablaDetalle = result.data.map((item) => {
            const { createAt, ...element } = item;
            return {
              createAt: moment(createAt).format("YYYY/MM/DD HH:mm"),
              ...element,
            };
          });
          console.log(result.data);
          this.verInformacionEncabezadoDetalle(item);
        })
        .catch((error) => {
          console.log(error);
        });
      this.dialog = true;
    },

    verInformacionEncabezadoDetalle(item) {
      this.informacionEncabezado.id = item.id;
      this.informacionEncabezado.tipoTransaccion = item.tipoTransaccion;
      this.informacionEncabezado.tipoDocumento = item.tipoDocumento;
      this.informacionEncabezado.numeroDocumento = item.numeroDocumento;
      this.informacionEncabezado.direccion = item.direccion;
      this.informacionEncabezado.numeroCelular = item.celular;
    },
  },

  watch: {
    "formulario.page": function () {
      this.getAllInfoTracking();
    },
    "formulario.itemPorPage": function () {
      this.getAllInfoTracking();
    },

    'formulario.codigoPedido': debounce(function () {
      this.formulario.page = 1
      this.getAllInfoTracking();
    }, 500),

    'formulario.tipoPedido': debounce(function () {
      this.formulario.page = 1
      this.getAllInfoTracking();
    }, 500),

    'formulario.numeroGuia': debounce(function () {
      this.formulario.page = 1
      this.getAllInfoTracking();
    }, 500),

    'formulario.estado': debounce(function () {
      this.formulario.page = 1
      this.getAllInfoTracking();
    }, 500),

    'formulario.nitTransportadora': debounce(function () {
      this.formulario.page = 1
      this.getAllInfoTracking();
    }, 500),

  },
  computed: {
    pageCount() {
      return this.formulario.totalPage;
    },
    ...mapState(["auth", "notify", "busy"]),
  },
};
</script>
 
<style scoped>
::v-deep .v-data-footer__pagination {
  display: none;
}

::v-deep .v-messages {
  display: none;
}

::v-deep .v-select::after {
  display: none;
  content: "Producto ";
}

::v-deep .cardDetalle table {
  overflow-y: scroll !important;
  max-height: 38vh;
  display: block !important;
}

::v-deep .filaPrincipal div label {
  font-size: 16px;
}

::v-deep .filaDetalle div label {
  font-size: 20px;
  color: black;
}

::v-deep .tablaPrincipal div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .tablaDetalle div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .tablaPrincipal div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

::v-deep .tablaDetalle div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
</style>